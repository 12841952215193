.locations__new_location_wrapper {
  margin-bottom: 10px;
}

.location_overview__total_devices{
  display: inline-block;
  padding: 5px 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-bottom: 10px;
}
