.devices__page_intro {
  margin-bottom: 30px;
}

.devices__device_card {
  margin: 0px;

  .card-body {
    position: relative;
    padding: 0px;
    padding-top: 100%;
  }

  &:hover {
    cursor: pointer;

    .card-body {
      box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.05);
      background-color: #fbfdff;
    }

    .devices__device_card_content.new_device {
      border-color: #4ce1b6;
    }
  }
}

.devices__device_card_content_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.new_device {
    padding: 10%;
  }
}

.devices__device_card_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.new_device {
    border: #f0f0f0 1px solid;
    border-radius: 0.25rem;
  }

  .mdi-icon {
    height: 50px;
    width: 50px;
    margin: 10px;
    color: $color-accent;
  }
}

.devices__device_card_content_device_details {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15%;

  .device_location {
    color: #646777;
    font-size: 12px;
  }

  .device_name {
    font-size: 14px;
    margin-top: 10px;
  }

  .mdi-icon {
    height: 100px;
    width: 100px;
    margin: 10px;
    color: #212529;
  }
}

.location-input-btn {
  color: $color-blue;
  text-decoration: underline;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
}

.new-device-btn {
  margin-bottom: 10px;
}
