.password_reset__request_form_container {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: #ecf4ff;
}

.password_reset__request_form_wrapper {
  margin: auto;
  padding: 10px;
}

.password_reset__request_form_card {
  background-color: #ffffff;
  padding: 25px 30px;
  max-width: 520px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.04);

  .card {
    padding-bottom: 0px;
  }

  .password_reset__login {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: $color-gray;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: $color-blue;
    }
  }
}

.password_reset__request_form_container .btn {
  width: 100%;
}
