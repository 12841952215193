.filter_bar {
  display: none;

  &.desktop {
    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  &.mobile {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.filter_bar__mobile_filter_menu_toggle {
  .mobile_toggle_btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      padding: 5px;
      svg {
        margin: 0px;
        width: 20px;
        height: 20px;
      }
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filter_bar__mobile_filter_menu {
  .filter_bar__filters_wrapper {
    display: flex;
    flex-direction: column;

    .mdi-icon {
      display: none;
    }

    .input-group {
      width: 100%;
      margin: 10px 5px 5px 5px;
    }

    .dropdown {
      width: 100%;
      margin: 5px;

      button {
        width: 100%;

        p {
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: space-between;
          align-items: center;

          .mdi-icon {
            display: block;
          }
        }
      }

      .dropdown__menu {
        width: 100%;
      }
    }
  }
}

.filter_bar__card_body {
  padding: 15px;

  .mobile_filter_menu {
    margin-top: 15px;
  }
}

.filter_bar__filters_wrapper {
  display: flex;
  justify-content: center;

  span {
    display: flex;
    align-items: flex-end;
  }
}

.filter_bar__filters_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .input-group,
  .dropdown,
  .mdi-icon {
    margin-right: 10px;
  }

  .input-group {
    width: 200px;
  }

  .dropdown {
    display: inline-block;
  }
}

.filter_bar__filter_controls_input {
  height: 43px;
  font-size: 14px;
}

.filter_bar__filter_controls_btn {
  margin: 0px;
}

.dropdown__menu {
  transform: none;
  max-height: 250px;
  overflow-y: auto;
}
