.Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: $color-accent !important;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: $color-red;
}
