.progress-wrap {
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .progress {
    height: fit-content;
    border-radius: 10px;
    box-shadow: none;
    background-color: #dddddd;
    margin-bottom: 0;
  }

  .progress-bar {
    background-color: $color-accent;
    color: #646777;
    box-shadow: none;
    height: 4px;
    text-align: right;
    padding-right: 10px;
    font-size: 9px;
    line-height: 13px;
  }

  p {
    margin-bottom: 5px;
  }

  .progress__label {
    font-size: 10px;
    bottom: 16px;
    margin: 0;
  }

  &.progress-wrap--small {
    text-align: left;

    .progress-bar {
      height: 8px;
    }
  }

  &.progress-wrap--rounded {
    .progress-bar {
      border-radius: 5px;
    }
  }

  &.progress-wrap--blue-gradient {
    .progress-bar {
      background-color: unset;
      background-image: linear-gradient(to left, #3eb6e6, #2d97dd);
    }

    .progress__label {
      color: #000;
    }
  }
}

.progress__item {
  text-align: left;
  display: flex;
  align-items: flex-end;
  width: 100%;

  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}

.progress__item_title {
  width: 40%;
  font-size: 13px;
  line-height: 1.38;
  color: $color-additional;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress__stat-progress {
  width: 60%;
  margin-bottom: 5px;

  &.progress--wide {
    width: 80%;
  }

  .progress__label {
    text-align: right;
  }
}
