.barchart_card {
  padding: 15px;
}

.barchart_card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .barchart_card__title {
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .mdi-icon {
      margin-right: 10px;
    }
  }
}

.device_dashboard__health-chart-units {
  color: $color-additional;
  margin-top: 5px;
}

.barchart_card__content {
  position: absolute;
  z-index: 2;
  top: 30px;

  .barchart_card__current_value {
    font-size: 48px;
    font-weight: 300;

    .barchart_card__current_unit {
      font-size: 12px;
    }
  }
}

.barchart_card__chat_wrapper {
  margin-top: 68px;
}
