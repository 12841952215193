.topBar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: $colorBackground;
}

.topBar__wrapper {
  position: relative;
  display: flex;
  height: 60px;
}

.topBar__button {
  width: 60px;
  height: 60px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $colorHover;
  }

  &.topBar__button--desktop {
    display: none;
  }
}

.topBar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px;
}

.topBar__logo {
  width: 150px;
  height: 32px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: none;
  background-image: $logoImg;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.topBar__right {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  margin-right: 15px;
}

.topBar__left {
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  width: 50%;
}

.topBar__avatar {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: $colorHover;
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
}

.topBar__avatar-img,
.topBar__avatar-name,
.topBar__icon {
  margin: auto 0;
}

.topBar__avatar-img {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}

.topBar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  margin-left: 10px;
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $colorText;
}

.topBar__icon {
  margin-left: 8px;
  height: 18px;
  margin-top: auto;
  fill: #b1c3c8;
}

.topBar__menu {
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: $colorBackground;

  button {
    padding: 0;

    &:hover {
      background-color: $colorHover;
    }
  }

  *:focus {
    outline: none;
  }
}

.topBar__menu-wrap {
  z-index: 101;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  min-width: 220px;
  right: 0;
}

.topBar__link {
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: $colorText;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 8px;
    background: #61cde8;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    background-color: $colorHover;

    &:before {
      opacity: 1;
    }
  }
}

.topBar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.topBar__link-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: $colorIcon;
}

.topBar__menu-divider {
  margin: 15px 0;
  border-top: 1px solid $colorBorder;
}

.topBar__profile {
  margin-bottom: 0;
  margin-left: 20px;
  position: relative;
}

.topBar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

@media screen and (min-width: 480px) {
  .topBar__avatar-name {
    display: block;
  }

  .topBar__menu {
    width: 100%;
    left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .topBar__button {
    &.topBar__button--desktop {
      display: block;
    }

    &.topBar__button--mobile {
      display: none;
    }
  }

  .topBar.topBar--navigation {
    .topBar__button.topBar__button--mobile {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .topBar__search {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .topBar__nav {
    display: flex;
  }

  .topBar.topBar--navigation {
    .topBar__logo {
      margin-left: 15px;
      display: block;
    }

    .topBar__button.topBar__button--mobile {
      display: none;
    }

    .topBar__avatar-name {
      display: none;
    }

    .topBar__profile {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .topBar.topBar--navigation {
    .topBar__avatar-name {
      display: block;
    }
  }
}

@media screen and (min-width: 1580px) {
  .topBar__nav-dropdown-toggle,
  .topBar__nav-link {
    width: 240px;
  }
}
