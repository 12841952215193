$colorBackground: white;
$colorBackgroundBody: #f2f4f7;
$colorHeaderDataPicker: #6db0ff;
$colorText: #3b3b3b;
$colorTextAdditional: #646777;
$logoImg: url(../../img/logo/mtg_logo_colour.png);
$colorHover: #fafbfe;
$colorFolderHover: #f0eeee;
$colorBorder: #eff1f5;
$colorIcon: #dddddd;
$imgInvert: invert(0%);
$colorFieldsBorder: #f2f4f7;
$colorBubble: rgba(242, 244, 247, 0.65);
$colorBubbleActive: rgba(234, 238, 255, 0.6);
$colorScrollbar: #b4bfd0;
$colorFitness: #646777;
$colorEmoji: #232329;
$color-accent: #009688;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #2e2e2e;
$color-additional-hover: darken($color-additional, 10%);
$color-yellow: #cca509;
$color-yellow-hover: darken($color-yellow, 10%);
$color-violet: #c88ffa;
$color-red: #d4102b;
$color-red-hover: darken($color-red, 10%);
$color-blue: #0a74bc;
$color-blue-hover: darken($color-blue, 10%);
$color-gray: #787985;
$color-light-gray: #f7f7f7;
$color-green: #030303;
$color-green-hover: darken($color-green, 10%);
