.report__filter-options {
  display: flex;
}

.report__date_picker {
  .rdrCalendarWrapper {
    width: 100%;

    .rdrMonth {
      width: 100%;
    }

    .rdrMonthPicker {
      width: 100%;
    }

    .rdrYearPicker {
      width: 100%;
    }
  }
}

.reports__report_card {
  margin: 0px;

  .card-body {
    position: relative;
    padding: 0px;
    padding-top: 60%;
  }

  &:hover {
    cursor: pointer;

    .card-body {
      box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.05);
      background-color: #fbfdff;
    }

    .reports__report_card_content.new_report {
      border-color: $color-accent;
    }
  }
}

.reports__report_card_content_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.new_report {
    padding: 10%;
  }
}

.reports__report_card_content {
  position: relative;
  height: 100%;

  &.new_report {
    border: #f0f0f0 1px solid;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mdi-icon {
    height: 50px;
    width: 50px;
    margin: 10px;
    color: $color-accent;
  }
}

.reports__report_card_content_report_details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  justify-content: space-between;

  & .btn {
    margin-bottom: 0px;
  }

  .report_last_ran {
    color: #646777;
    font-size: 12px;
  }

  .report_name {
    font-size: 14px;
    margin-top: 0px;
  }

  .mdi-icon {
    height: 100px;
    width: 100px;
    margin: 10px;
    color: #212529;
  }
}

.reports__report_actions_btn_wrapper {
  & button {
    margin-right: 0px;
    display: block;
    width: 50%;
    text-align: center;

    & p {
      align-items: center;
      display: block;

      & svg {
        margin-top: 0px;
        height: 18px;
        width: 18px;
      }
    }
  }
}

.reports__report_details_devices,
.reports__report_details_measurements {
  margin-top: 20px;
  // background-color: #f9f9f9;
  // padding: 10px;
  // border-radius: 5px;

  & span {
    display: inline-block;
    background-color: #d0e3ff;
    padding: 2px 12px;
    border-radius: 5px;
    margin: 5px 5px 5px 0px;
  }
}

.report-download-btn {
  float: right;
  margin: 0;
  margin-left: 20px;
}

.report-runs-table {
  .Completed,
  .Processing {
    padding: 5px 15px;
    color: white;
    border-radius: 5px;
  }

  .Completed {
    background-color: $color-accent;
  }

  .Processing {
    background-color: #dfa527;
  }
}

.form__form-group_checkbox {
  display: flex;
  align-items: center;

  .checkbox {
    width: 12px;
    height: 12px;
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
