.site__site_performance_chart_wrapper {
  .site__site_performance_row {
    display: flex;
    flex-direction: row;
    .site__site_performance_chart_wrapper {
      width: 50%;
    }
  }
}

.site__site_total_devices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;

  .value {
    font-size: 44px;
    font-weight: 600;
  }
}

.site__site_average_card {
  background-color: #e9f4ff;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin: 10px;

  .site__average_card_label {
    padding: 3px 10px;
    font-weight: 500;
    background-color: #4698e0;
    display: flex;
    align-items: center;
    width: 100px;
    border-radius: 5px 0 0 5px;
  }

  .site__average_card_value {
    text-align: center;
    padding: 9px;
    font-size: 25px;
    font-weight: 600;
    color: #646777;

    .unit {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.site__location_performance {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 25px;
  max-height: 50px;
  background-color: #f4f4f4;

  .site__location_performance_location_card {
    display: flex;
    flex-direction: row;
    align-items: center;

    .location_name {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        text-decoration: underline;
      }
    }
  }
}

.site__location_performance_graph {
  &-item {
    text-align: left;
    display: flex;
    align-items: flex-end;
    width: 100%;

    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
  &-title {
    width: 40%;
    font-size: 13px;
    line-height: 1.38;
    color: $color-additional;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-progress {
    width: 60%;
    margin-bottom: 5px;

    &.progress--wide {
      width: 80%;
    }

    .progress__label {
      text-align: right;
    }
  }
}

.site__location_performance_graph-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    visibility: hidden;
    width: 17px;
    height: 17px;
  }

  &.location_performance_alert {
    svg {
      visibility: visible;
      fill: $color-red;
      width: 17px;
      height: 17px;
    }
  }
}

.site__total_locations {
  display: inline-block;
  padding: 5px 10px;
  background-color: $color-light-gray;
  border-radius: 5px;
  margin-bottom: 10px;
}

.site_locations_table {
  .table-responsive {
    max-height: 220px;
  }
}

.dashboard__current-users {
  display: flex;
  align-items: center;
  flex-direction: column;

  .dashboard__current-users-chart {
    position: relative;
    width: 100%;

    div,
    svg {
      width: 100% !important;
      margin: 0 auto;
    }

    .dashboard__current-users-label {
      margin: 0;
      position: absolute;
      top: calc(50% + 10px);
      text-align: center;
      font-size: 38px;
      width: 100%;
    }
  }

  .dashboard__current-users-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-size: 13px;
    line-height: 1.54;
    color: #555555;

    p {
      margin: 0;
    }

    .dashboard__current-users-day {
      display: flex;
      flex-direction: column;
      align-items: center;

      & span:last-child {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 500;
        color: #555555;
      }
    }
  }

  @media screen and (min-width: 1539px) {
    .dashboard__current-users-info {
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1539px) {
    .dashboard__current-users-chart {
      svg,
      div {
        height: 100px !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .dashboard__current-users-info {
      padding: 0 75px;
    }
  }
}

.site__max_value {
  color: #bbbbbb;
  margin-top: -10px;
  font-size: 11px;
  text-align: center;
}

.site__total_count_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  .site_total_count_content {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 95%;

    .border {
      border-right: 2px solid #d7d7d7;
    }

    span {
      font-size: 34px;
      font-weight: 300;
    }
  }
}
