.material-table {
  th {
    white-space: nowrap;
  }
}

.material-table__toolbar-wrap {
  position: absolute;
  top: 10px;

  .material-table__toolbar {
    padding: 0 30px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__row {
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $color-light-gray;
  }
}

.material-table__checkbox--checked span {
  color: $color-accent;
}

.material-table__cell-right {
  text-align: left;
}

.material-table__cell.material-table__cell--sort {
  span {
    transition: 0.3s;

    color: $colorText;

    &:hover {
      color: $color-accent;
    }
  }
}

.material-table__cell,
.material-table__sort-label,
.material-table__toolbar-button {
  color: $colorText;
}

.material-table__checkbox {
  transition: 0.3s;
  color: $colorText;
}

.material-table__pagination {
  float: right;
  margin-top: 25px;

  button:hover {
    background-color: $colorHover !important;
  }

  & > div {
    padding: 0;
  }

  span,
  div,
  svg {
    color: $colorText;
  }
}

.material-table__wrap {
  overflow-x: auto;
}

.material-table__filter-menu {
  div:last-child {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .material-table__filter-menu-item {
    color: #646777;
    transition: 0.3s;
    font-size: 13px;
    padding: 7px 24px;
    height: auto;

    &:hover {
      background: #fafbfe;
    }
  }
}

@media screen and (max-width: 768px) {
  .material-table__pagination {
    div {
      margin-left: 8px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0px;
      }

      &:first-child {
        margin: 0;
      }
    }

    div > span:first-of-type {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .material-table__toolbar-selected {
    span {
      display: none;
    }
  }
}

.material-table__table-head {
  border-bottom: solid 2px #e6e6e6;
}

.material-table__cell {
  border-bottom: 1px solid #f3f3f3 !important;
  padding: 8px !important;
}

.material-table__cell_action {
  width: 100%;
  text-align: right;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-columnsContainer {
  border-bottom: solid 2px #e6e6e6 !important;
}

.MuiDataGrid-menuIconButton {
  background-color: $colorBackgroundBody !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem;
}

.material_data_table__cell_btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .btn {
    padding: 5px 10px !important;
  }
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiDataGrid-cell {
  font-size: 13px !important;

  a {
    text-decoration: underline !important;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: 13px !important;
}

.MuiDataGrid-columnHeader[data-field="actions"] {
  .MuiDataGrid-columnSeparator {
    display: none;
  }
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

//Desktop
.MuiDataGrid_Toolbar__custom {
  display: none;
  &.desktop {
    @media screen and (min-width: 769px) {
      display: flex !important;
      justify-content: space-between !important;
      align-items: flex-start !important;
      margin-bottom: 15px;
    }
  }
}

//Mobile
.MuiDataGrid_Toolbar__custom {
  display: none;

  &.mobile {
    @media screen and (max-width: 768px) {
      display: block;

      .MuiDataGrid_Toolbar__mobile_filter_menu {
        background-color: #f2f4f7;
        padding: 10px;
        border-radius: 5px;

        .MuiDataGrid_Toolbar__mobile_filter_menu_filters {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
        }
      }

      .MuiDataGrid_search_input__custom {
        width: 100%;
        margin-bottom: 10px;
      }

      .MuiDataGrid_export__custom {
        width: 100%;
        margin: 0 !important;
      }

      .MuiDataGrid_filter_button {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
        background-color: #009688;
        color: #fff;
        font-weight: 300;
      }

      .MuiDataGrid_Toolbar__menu_toggle {
        .mobile_toggle_btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button {
            padding: 5px;
            svg {
              margin: 0px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.MuiDataGrid_Toolbar__custom {
  .MuiDataGrid_search_input__custom {
    background-color: #f2f4f7;
    padding: 2px 10px;
    border-radius: 5px;

    .css-1480iag-MuiInputBase-root-MuiInput-root {
      &::before,
      &::after {
        border: none !important;
      }
    }
  }

  .MuiDataGrid_filter_button {
    background-color: #f2f4f7;
    color: #666;
    margin-right: 10px;
    padding: 5px 10px;
    -webkit-transition: none !important;
    transition: none !important;

    &:hover {
      background-color: #f2f4f7;
    }
  }

  .MuiDataGrid_export__custom {
    background-color: #009688;
    padding: 8px 10px;
    border-radius: 5px;
    color: #ffffff;
    align-items: flex-start;
    text-transform: none;
    font-size: 14px;
    font-weight: 300;

    &:hover {
      background-color: #009688;
    }
  }
}

.MuiDataGrid-panel {
  width: 100%;
  display: flex;
  justify-content: center;

  .MuiDataGrid-filterForm {
    display: flex !important;
    flex-direction: column !important;

    .MuiFormControl-root {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      margin-top: 5px;
      max-width: 300px;

      .MuiButtonBase-root::before {
        content: "Clear Filter";
        font-size: 14px;
      }

      .MuiButtonBase-root:hover {
        border-radius: 0 !important;
        transition: none !important;
        -webkit-transition: none !important;
      }

      .MuiInputLabel-root {
        margin-bottom: -10px;
        display: block;
        position: relative;
        height: 18px;
      }

      .MuiInput-root {
        margin-top: 0px;
      }

      .MuiTextField-root {
        padding: 0px;
        margin-top: 0px;
      }
    }
  }
}

.MuiChip-root {
  &.Completed {
    color: $color-accent;
    border: 1px solid $color-accent;

    svg {
      color: inherit;
    }
  }

  &.Processing {
    color: $color-yellow;
    border: 1px solid $color-yellow;

    svg {
      color: inherit;
    }
  }
}
