.modal-dialog {
  max-width: 500px;
}

.modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 50px 40px 60px 40px;
}

.modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px;
}

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
  }
}

.modal-content {
  form {
    input,
    select {
      border: 1px solid #cccccc;
      color: #646777;
      border-radius: 4px;
    }
  }
}
