form {
  display: flex;
  flex-wrap: wrap;

  input,
  textarea,
  select {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    max-width: 500px;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    border: 1px solid $colorFieldsBorder;
    color: $colorText;

    &[disabled] {
      background: $colorBackgroundBody;
    }

    &[disabled] {
      background: $colorBackgroundBody;

      &:focus,
      &:active {
        border-color: $colorBorder;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-accent solid 1px;
    }
  }
}

.form__form-group {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-field_multiSelect {
  width: 100%;
  display: block;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: $colorText;

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  background: $colorFieldsBorder;
  border: 1px solid $colorFieldsBorder;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  background: $colorFieldsBorder;
  border: $colorFieldsBorder;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__error_message {
  color: #dc1528;
  font-size: 11px;
  display: block;
  height: 18px;
}

.form__form-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__from-switch-wrapper {
  width: 100%;
  max-width: 500px;
  background-color: #f7f8f9;
  padding: 10px;
  margin: 20px 0px;
  border-radius: 5px;
}

.form__form_edit_btn_wrapper {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn.icon {
    padding: 5px 10px;
    margin-bottom: 0;
  }

  .btn-group {
    margin-bottom: 0;
  }
}

.form__info_tooltip {
  display: flex;
  align-items: center;

  .form__form-group-label {
    margin-bottom: 0px;
    line-height: 1;
  }

  .mdi-icon {
    width: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}
