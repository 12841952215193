.linechart_card {
  padding: 15px;
}

.linechart_card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .linechart_card__title {
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .mdi-icon {
      margin-right: 10px;
    }
  }
}

.device_dashboard__health-chart-units {
  color: $color-additional;
  margin-top: 5px;
}

.linechart_card__content {
  position: absolute;
  z-index: 2;
  top: 30px;

  .linechart_card__current_value {
    font-size: 48px;
    font-weight: 300;

    .linechart_card__current_unit {
      font-size: 12px;
    }
  }
}

.linechart_card__chat_wrapper {
  margin-top: 68px;
}


.linechart__current_value_wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #f7f7f7;
    display: inline-block;
    padding: 0 10px;
    border-radius: 5px;
  
    .linechart__current_value {
      font-size: 36px;
      font-weight: 300;
  
      .linechart__current_unit {
        font-size: 12px;
      }
    }
  }