.login {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: #ecf4ff;
}

.login__wrapper {
  margin: auto;
  padding: 10px;
}

.login__card {
  background-color: #ffffff;
  padding: 25px 30px;
  max-width: 520px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.04);
}

.login__profile {
  text-align: center;
}

.login .btn {
  width: 100%;
}

.forgot_password {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-size: 12px;
  color: $color-gray;

  &:hover {
    cursor: pointer;
    color: $color-blue;
  }
}

.login__login_btn {
  height: 46px;
  padding: 0;
}

.login__loading_spinner {
  color: #ffffff;
  height: 30px;
  width: 30px;
}
