.device_model_tag {
  position: absolute;
  top: 10px;
  left: 8px;
  color: #646777;

  span {
    background-color: #b1f1ff;
    padding: 5px 10px;
    border-radius: 19px;
  }
}
