.profile {
}

.profile__card {
  height: 100%;
  padding: 0;
  background-color: $colorBackground;

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 30px 40px 30px;
  }

  &--calendar {
    .rbc-toolbar {
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }
}

.profile__information {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    align-items: center;
  }
}

.profile__avatar {
  width: 100%;
  margin-bottom: 15px;

  img {
    max-height: 140px;
    max-width: 140px;
    border-radius: 50%;
  }
}

.profile__data {
  width: 100%;
}

.profile__name {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
}

.profile__company {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
}

.profile__user_information_details {
  margin: 10px 0px;
}

.profile__user_information_details_head {
  font-size: 12px;
  margin: 0px;
  font-weight: 600;
}

.profile__user_information_details_content {
  margin: 0px;
}
