.MuiPaper-elevation8 {
  box-shadow: 0px 5px 5px -3px #00000005, 0px 8px 10px 1px #00000005, 0px 3px 14px 2px #00000005 !important;
}

.material_data_table__cell_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
