.dashboard-table {
  tbody {
    tr {
      text-align: left;
      td {
        vertical-align: middle;
        .btn {
          margin: 0;
          margin-right: 20px;
          float: right;

          @media screen and (max-width: 1012px) {
            &.first {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.latest-measurements-filter {
  padding: 15px;

  .dropdown {
    display: inline;
    margin-left: 15px;

    button {
      max-width: 250px;
      p {
        justify-content: space-between;
      }
    }
  }
}

.latest_measurements__value_tab {
  display: flex;
  flex-direction: column;
  height: 80%;
  background-color: #ebf5ff;
  padding: 8px 8px;
  border-radius: 5px;
  border: solid 1px #c9daea;
  margin-right: 10px;

  .latest_measurements__name {
    display: flex;
    height: 50%;
    align-items: center;
    font-size: 11px;
    margin-bottom: 5px;
    color: #787878;
    text-transform: capitalize;
  }

  .latest_measurements__value {
    height: 50%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
  }
}
