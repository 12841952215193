.device_details__page_intro {
  margin-bottom: 30px;
}

.device_details__details_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.device_details__card_body {
  display: flex;
  justify-content: center;
}

.device_details__device_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;

  img {
    max-width: 300px;
    max-height: 201px;
  }
}

.device_details__device_status {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .status {
    padding: 0px 10px;
    color: white;
    text-transform: uppercase;
    border-radius: 17px;
    font-size: 12px;
    line-height: 2;
    text-align: center;

    &.online {
      background-color: $color-accent;
    }

    &.offline {
      background-color: $color-red;
    }
  }

  .battery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-top: 12px;

    svg {
      fill: #24d6a3;
      stroke: #24d6a3;
      stroke-width: 2px;
    }
  }
}

.device_details__Device_details_header {
  display: flex;
  justify-content: space-between;

  .btn-group {
    margin: 0;
  }
}

.device_details__actions_btn_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    margin-bottom: 30px;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 286px;

    button {
      justify-content: center;
      display: flex;
      margin: 0;
      width: 100%;
    }
  }

  button {
    justify-content: center;
    display: flex;
    margin: 0;
    width: 100%;
    max-width: 286px;
  }
}
